<template>
    <div class="col-12">
        <card type="filter">
            <div class="row">
            <div class="col-12 col-md-4">
                <h2>{{$t('vision.title')}}</h2>
            </div>
            <div class="col-12 col-md-5 rango">
                <!--<v-md-date-range-picker ref="rangeInput"
                    :start-date="computedStart"
                    :end-date="computedEnd"
                    max-year="2020"
                    min-year="2020"
                    show-year-select
                ></v-md-date-range-picker>
                <label>Rango de fechas:</label>-->
                <date-range-picker 
                    v-model="dateRange" 
                    :startDate="startDate" 
                    :endDate="endDate" 
                    :locale-data="locale"
                    :opens="opens"
                    :autoApply="autoApply"> 
                    <!--Optional scope for the input displaying the dates --> 
                    <div slot="input" slot-scope="picker"> 
                        {{$t('vision.date')}}: {{ picker.startDate | date}} - {{ picker.endDate | date}} 
                    </div> 
                </date-range-picker>
            </div>
            <div class="col-12 col-md-3">
                
                
                <!--<router-link
                :to="{name: "Dashboard", params: {id:item}}"
                v-for="(item,index) of fotosArreglo"
                :key="index"
                class="nav-link"
                >
                <button>prueba {{item}}</button>
                </router-link>-->
            </div>
            </div>
        </card>
    </div>
</template>

<script>
import Card from "@/components/card/Card.component.vue";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker"; 
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateUtil from "@/components/rangedatesocial/RangeDatePicker.js";
import { createHelpers } from 'vuex-map-fields';
const { mapFields } = createHelpers({
  getterType: 'vision/getField',
  mutationType: 'vision/updateField',
});

var inicio = new Date()
var despe = new Date(inicio.setDate(inicio.getDate() - 30))
inicio = moment(inicio).format('YYYY-MM-DD')
despe = moment(despe).format('YYYY-MM-DD')
export default {
  name: "RangeDateSocial",
  components: {
    Card,
    DateRangePicker
  },
  data() {
    return {
            startDate: despe, 
            endDate: inicio, 
            opens: "left",
            autoApply: true,
            locale: {
                // direction: 'ltr',
                format: 'DD-MM-YYYY',
                separator: ' - ',
                applyLabel: 'Apply',
                cancelLabel: 'Cancel',
                weekLabel: 'W',
                customRangeLabel: 'Custom Range',
                daysOfWeek: ['Dom', 'Lun', 'Mar', 'Mier', 'Jue', 'Vie', 'Sab'],
                monthNames: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
                firstDay: 0
            },
            //locale: DateUtil,
    }
  },
  filters: {
      date(val) {
        return val ? val.toLocaleDateString() : ''
      }
    },
  props: {

    
  },
  computed: {
    computedEnd() {
      let end = new Date();
      return moment(end, "YYYYMMDD").format("MM/DD/YYYY");
    },
    computedStart(){
      debugger
        let end = new Date();
        let start = end.setDate(end.getDate() - 8 );
        start = new Date(start);
        return moment(start, "YYYYMMDD").format("MM/DD/YYYY");
    },
    computedEnd2(){
      debugger
        console.log(picker.endDate)
        return moment(picker.endDate, YYYYMMDD ).format("MMM Do YY"); 
    },
    ...mapFields(["dateRange"]),
  },
  created() {

  }, 
  methods: {
      dateFormat (classes, date) {
        if (!classes.disabled) {
          classes.disabled = date.getTime() < new Date()
        }
        return classes
      }
  },
  mounted() {
    //   this.$refs.rangeInput.$children[0].$el.children[2].textContent = "Rango de Fechas"
  }
};
</script>